<template>
  <div>
    <v-card>
      <v-data-table :headers="headers" :items="data">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Wewenang</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "Nomor",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "Wewenang", align: "start", value: "wewenang" },
      { text: "Action", value: "action", width: "100px", sortable: false },
    ],

    data: [],
  }),
};
</script>