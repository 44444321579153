<template>
  <div>
    <v-card class="pb-5">
      <v-data-table
        :headers="headers"
        :items="SData"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Bahan Kerja</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.nomor`]="{ item }">
          {{
            SData.map(function (x) {
              return x.id;
            }).indexOf(item.id) + 1
          }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import SInfojab from "@/store/anjab/infojabatan/infojab";

export default {
  computed: {
    SData: {
      get() {
        return SInfojab.state.tugas;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  data: () => ({
    headers: [
      {
        text: "Nomor",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "Bahan Kerja", align: "start", value: "bahan.kerja" },
      { text: "Penggunaan Dalam Tugas", align: "start", value: "bahan.penggunaan" },
      // { text: "Action", value: "action", width: "100px", sortable: false },
    ],

    data: [],
  }),
};
</script>
