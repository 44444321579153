<template>
  <div v-if="jdata">
    <v-card class="px-5 mb-10">
      <v-row>
        <v-col cols="12" md="11" class="my-auto">
          <h3 class="primary--text">
            {{ unor_name.toUpperCase() }}
          </h3>
          <h4 class="primary--text font-weight-light" v-if="jdata.namaJabatan">
            DETAIL JABATAN - {{ jdata.namaJabatan.toUpperCase() }}
          </h4>
        </v-col>
      </v-row>
    </v-card>

    <div>
      <v-slide-group v-model="tab" show-arrows>
        <v-slide-item
          v-for="item in tabs"
          :key="item.id"
          v-slot="{ active, toggle }"
        >
          <v-btn
            class="mx-2"
            :input-value="active"
            :color="active ? 'primary' : 'blue-grey lighten-4'"
            depressed
            rounded
            @click="toggle"
          >
            {{ item.nama }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }} Edit Data Jabatan
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div class="my-5">
      <CIkhtisarjab class="mb-5" v-if="tab == 0"/>

      <CUraiantugas class="mb-5" v-if="tab == 1"/>

      <CBahankerja class="mb-5" v-if="tab == 2"/>

      <CPerangkat class="mb-5" v-if="tab == 3"/>

      <CHasilkerja class="mb-5" v-if="tab == 4"/>

      <CTanggungjawab class="mb-5" v-if="tab == 5"/>

      <CWewenang class="mb-5" v-if="tab == 6"/>

      <CPrestasikerja class="mb-5" v-if="tab == 7"/>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import SView from "@/store/anjab/infojabatan/view";
import SInfojab from "@/store/anjab/infojabatan/infojab";

import CIkhtisarjab from "@/components/anjab/infojabatan/ikhtisarjab/view";
import CUraiantugas from "@/components/anjab/infojabatan/uraiantugas/view";
import CBahankerja from "@/components/anjab/infojabatan/bahankerja/view";
import CPerangkat from "@/components/anjab/infojabatan/perangkat/view";
import CHasilkerja from "@/components/anjab/infojabatan/hasilkerja/view";
import CTanggungjawab from "@/components/anjab/infojabatan/tanggungjawab/view";
import CWewenang from "@/components/anjab/infojabatan/wewenang/view";
import CPrestasikerja from "@/components/anjab/infojabatan/prestasikerja/view";

export default {
  components: {
    CIkhtisarjab,
    CUraiantugas,
    CBahankerja,
    CPerangkat,
    CHasilkerja,
    CTanggungjawab,
    CWewenang,
    CPrestasikerja,
  },

  data: () => ({
    token: Cookie.get("token"),
    unor_name: localStorage.getItem("unor"),

    jdata: {},

    tab: 0,

    tabs: [
      {
        id: "1",
        nama: "Ikhtisar",
      },
      {
        id: "2",
        nama: "Uraian Tugas",
      },
      {
        id: "3",
        nama: "Bahan Kerja",
      },
      {
        id: "4",
        nama: "Perangkat / Alat Kerja",
      },
      {
        id: "5",
        nama: "Hasil Kerja",
      },
      {
        id: "6",
        nama: "Tanggung Jawab",
      },
      {
        id: "7",
        nama: "Wewenang",
      },
      {
        id: "8",
        nama: "Prestasi Kerja yang diharapkan",
      },
    ],
  }),

  computed: {
    refresh: {
      get() {
        return SView.state.refresh;
      },
    },

    alertMassage: {
      get() {
        return SView.state.alertMassage;
      },
    },

    success: {
      get() {
        return SView.state.success;
      },
      set(value) {
        SView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return SView.state.alertBerhasil;
      },
      set(value) {
        SView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return SView.state.alertGagal;
      },
      set(value) {
        SView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  async mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_API + "anjab/id/" + this.$route.params.id;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          SView.commit("refreshData", false);
          this.ldata = false;
          this.jdata = res.data.mapData[0];
          this.getInfojab();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getInfojab() {
      const url =
        process.env.VUE_APP_API + "infojab/getall/" + this.$route.params.id;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          SInfojab.commit("VIHKTISAR", res.data.mapData.ihktisar);
          SInfojab.commit("VTUGAS", res.data.mapData.tugas);
          SInfojab.commit("VTANGGUNGJAWAB", res.data.mapData.tanggungjawab);
          SInfojab.commit("VWEWENANG", res.data.mapData.wewenang);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    toggle(value) {
      console.log(value);
    },
  },
};
</script>
